@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('./styles/card-product.css');
@import url('./styles/footer.shared.css');
@import url('./styles/home.css');
@import url('./styles/media-queries.css');

:root {
  --color-primary: #BB4814;
  --color-secondary: #546513;

  --color-white: #FAFCFE;

  --color-black: #18202D;

  --color-gray: #A2A7BB;
  --color-gray-light: #DEDFE1;
  --color-gray-dark: #a0a0a0;

  --color-green: #3C9820;
  --color-green-soft: #63AC4C;
  --color-green-dark: #19410D;

  --color-red: #CC1010;
  --color-red-soft: #2C2C2C;
  --color-red-dark: #570606;

  --color-blue: #4580E5;

  --font-nunito: 'Open Sans', sans-serif;
  --lh: 1.4rem;
  --default-gap: 1rem;
  --padding-block: 10%;
  --botder-radius: 4px;

  --font-size-lg-mobile: 20px;
  --font-size-md-mobile: 16px;
  --font-size-sm-mobile: 12px;

  --font-size-lg-desktop: 24px;
  --font-size-md-desktop: 16px;
  --font-size-sm-desktop: 12px;
}

/* SEMANTICS COLORS */
:root {
  --button-border-radius: 8px;

  --background-light: var(--color-white);
  --background-muted: var(--color-gray-light);
  --background-success: var(--color-green);
  --background-error: var(--color-red);

  --background-gray: var(--color-gray);
  --border-dark: var(--color-black);
  --background-blue: var(--color-blue);

  --button-background: var(--color-primary);
  --default-font: var(--font-nunito);

  --success: var(--color-green);
  --success__hover: var(--color-green-soft);
  --selected: var(--color-green-dark);
  --gap: var(--default-gap);
  --info: var(--color-blue);
  --danger: var(--color-red);

  /* buttons */
  --button-primary-background: var(--color-blue);
  --button-secondary-background: var(--color-gray);
  /* ------ */

  /* icon */
  --icon-light-background: var(--color-white);
  /* ------ */

  /*TEXT */
  --text-color-primary: var(--color-black);
  --text-color-secondary: var(--color-white);
  --text-color-success: var(--color-green);
  --text-color-error: var(--color-red);
  --text-color-muted: var(--color-gray);

  --text-light: var(--color-white);
  --text-green: var(--color-green);
  --text-gray: var(--color-gray-light);
  --text-danger: var(--color-red);
  --text-dark: var(--color-dark);
  --text-light: var(--color-white);
  --text-info: var(--color-blue);

  /* borders */
  --border-color: var(--color-gray-light);
}

html,
body {
  height: auto;
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--background-light);
  font-family: var(--default-font);
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}



.button--primary {
  color: var(--text-light);
  background-color: var(--button-primary-background);
}

/* ------ */

/* text */
.text {
  font-family: var(--default-font);
}

.text--bold {
  font-weight: 700;
}

.text--primary {
  color: var(--text-color-primary);
}

.text--secondary {
  color: var(--text-color-secondary);
}

.text--success {
  color: var(--text-color-success);
}

.text--muted {
  color: var(--text-color-muted);
}

.text--error {
  color: var(--text-color-error);
}

/* ----- */

/* icons */
.icon {}

.icon--size-xl {
  transform: scale(3);
}

.icon--light {
  color: var(--icon-light-background);
}

/* ----- */

/* Borders */
.border {
  border: 1px solid;
  border-radius: 8px;
  box-shadow: none;
}

.border--light {
  border-color: #A2A7BB;
}

/* ---- */

/* Images */
.image {
  width: 100%;
  height: 100%;
}

/* ---- */

.header--light {
  background-color: var(--background-light);
}

/* FORMS */
form {
  width: 100%;
}

/* FORMS */

/*STATES*/
.text--danger {
  color: var(--text-danger);
}


/* LABELS */

h1,
h2,
h3,
h5 {
  margin: 0;
}

/* TEXT STYLES */
.text--sm {
  font-size: 0.87em;
  font-weight: 400;
}

.text-md--success {
  font-size: 1em;
  font-weight: 800;
  color: var(--success);
}

.text--dark {
  color: var(--text-dark);
}

.text--light {
  color: var(--text-light);
}

.text--blue {
  color: var(--info);
}

.text-subtitle {
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
}

.text-center {
  text-align: center;
}

.text--uppercase {
  text-transform: uppercase;
}

/* TEXT END */

/* LAYOUTS */
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content--center {
  align-items: center;
  justify-content: space-between;
}

.content--left {
  align-items: flex-start;
  justify-content: space-between;
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.label-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.gap--md {
  gap: var(--default-gap);
}


.gap--sm {
  gap: 0.5rem;
}

/* CARDS */
.card {
  border-radius: 8px;
  padding: 16px;
}

.card--box-shadow {
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.46);
}

.card--lg {
  width: 22.1rem;
}

.card--md {
  width: 16.25rem;
}

.card-xl {
  width: 83rem;
}

.card--light {
  background-color: var(--background-light);
}


/* ICONS */
.icon {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* STATES */
.strikethrough {
  text-decoration: line-through;
}

/* --ANGULAR MATERIAL CUSTOM-- */

/* STEPPER */


.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-stepper-horizontal {
  width: 100%;
}

/* CALENDAR */

.day--available .mat-calendar-body-cell-content {
  color: white;
  background-color: var(--success);
}

.mat-calendar-body-cell-content:hover {
  color: var(--text-dark);
  background-color: var(--success__hover) !important;
}

.mat-calendar-body-cell:hover .mat-calendar-body-cell-content {
  color: white;
  background-color: var(--success__hover) !important;
}

.mat-calendar-body-selected {
  color: white !important;
  background-color: var(--selected) !important;
}

.mat-expansion-panel-body {
  max-height: 40rem;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mat-expansion-panel-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mat-expansion-panel-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* SELECT */
.mat-mdc-select-trigger {
  width: 100%;
  height: 3rem;
  align-items: center;
  justify-content: center;
}

.client-form--localization .mat-mdc-select-trigger {
  height: auto !important;
}

.mat-stepper-horizontal {
  background-color: transparent;
}



.layout {
  display: flex;
  width: 100%;
  height: 100%;
}

.layout--column {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
}

/* layout */
.content-layout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.disclaimer-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.margin-border {
  margin: 0.5rem;
}

/* text general styles */

.section-title {
  font-family: Mulish;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--text-dark);
}

.section-subtitle {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-dark);
}

.section-disclaimer {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--text-dark);
}

.product-title {
  font-family: Mulish;
  font-size: 15px;
  font-weight: 800;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.product--price {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

.product--price--green {
  color: #1A9608;
}

.product--detail-text {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

.price--title {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.price--subtitle {
  font-family: Mulish;
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
}

.price--total {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.title {
  font-weight: 800;
  font-size: 15px;
  color: var(--text-dark);
}

.subtitle {
  font-weight: 600;
  font-size: 10px;
}

.subtitle--light {
  color: #777777;
}

.section-subtitle--green {
  color: #3C9820;
}

.numeric-text {
  font-weight: 800;
  font-size: 1rem;
}

.numeric-text--small {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

.numeric-text--dark {
  color: var(--text-dark);
}

.numeric-text--green {
  color: var(--text-color-success);
}

.text-icon-layout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text--error {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--text-color-error)
}

/* buttons */

.button {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--theme-color-primary, gray);
  font-size: 14px;
  text-transform: uppercase;
  gap: 0.5rem;
  border-radius: 1.5rem;
}

.button--primary {
  background-color: var(--theme-color-primary, gray);
  color: #FFFFFF;
}

.button--success {
  background-color: var(--success);
  border-color: var(--success);
  color: #FFFFFF;
}

.button--primary-outline {
  background-color: transparent;
  color: var(--theme-color-primary, gray);
  border: 1px solid var(--theme-color-primary, gray);
}

.button--text {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

/* icons */
.icon {}

.icon--primary {
  color: var(--theme-color-primary, gray);
}

.icon--success {
  color: var(--success);
}

/* display interaction class */
.hidden {
  display: none !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.mobile {
  display: flex;
}

.desktop {
  display: none;
}

.booking-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.info-disclaimer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-disclaimer span {
  text-align: left;
}

.resume-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment-block {
  border: solid 1px var(--color-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.payments-methods {
  display: flex;
}

/* For large screens */
@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: flex;
  }

  .resume-column {
    display: flex;
    flex-direction: column;
    width: 25rem;
    gap: 1rem;
  }

  .booking-column {
    display: flex;
    flex-direction: column;
    width: 22rem;
  }
}

/* For large screens */
@media screen and (min-width: 1680px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: flex;
  }
}
